// ========================================================================== //
// Typography
// ========================================================================== //


uppercase()
  text-transform uppercase
  letter-spacing .05em


undo-uppercase()
  text-transform none !important
  letter-spacing auto !important
