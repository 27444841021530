// Dependencies
// ========================================================================== //

@require 'breakpoints'
@require 'spacing'




// Sections
// ========================================================================== //

section(spacer-property = padding)
  {spacer-property}-top space(sm)
  {spacer-property}-bottom space(sm)

  +breakpoint-up(sm)
    {spacer-property}-top space(md)
    {spacer-property}-bottom space(md)

  +breakpoint-up(lg)
    {spacer-property}-top space(lg)
    {spacer-property}-bottom space(lg)




// Containers
// ========================================================================== //

container(spacer-property = padding)
  unless spacer-property == margin
    margin-left auto
    margin-right auto
  {spacer-property}-left space(sm)
  {spacer-property}-right space(sm)

  +breakpoint-up(sm)
    {spacer-property}-left space(md)
    {spacer-property}-right space(md)

  +breakpoint-up(lg)
    {spacer-property}-left space(lg)
    {spacer-property}-right space(lg)

container-width()
  max-width container-max-width




// Grid
// ========================================================================== //

column(gutter-property = padding)
  {gutter-property}-left space(sm, .5)
  {gutter-property}-right space(sm, .5)

  +breakpoint-up(sm)
    {gutter-property}-left space(md, .5)
    {gutter-property}-right space(md, .5)

  +breakpoint-up(lg)
    {gutter-property}-left space(lg, .5)
    {gutter-property}-right space(lg, .5)


row()
  margin-left space(sm, -.5)
  margin-right space(sm, -.5)

  +breakpoint-up(sm)
    display flex
    margin-left space(md, -.5)
    margin-right space(md, -.5)

  +breakpoint-up(lg)
    margin-left space(lg, -.5)
    margin-right space(lg, -.5)


row-container()
  column()
  margin-left auto
  margin-right auto




// Layers
// ========================================================================== //


overlay(offset = 0)
  position absolute
  top offset
  right offset
  bottom offset
  left offset

fill-parent(vpos=top, hpos=left)
  position absolute
  {vpos} 0
  {hpos} 0
  width 100%
  height 100%

