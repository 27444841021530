.typed-cursor {
  opacity: 1;
  animation: blink 0.7s infinite;
}

@keyframes blink{
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.js .typed-strings {
  display: none;
}
