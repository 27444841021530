/* ==========================================================================
   Buttons
   ========================================================================== */


/* Base styles
   ========================================================================== */

.button
  display inline-block
  font-weight bold
  line-height 1.25
  text-align center
  uppercase()
  white-space nowrap
  vertical-align middle
  cursor pointer
  user-select none
  button-size(button-padding-y, button-padding-x, button-border-radius)
  outline-color transparent
  outline-style solid
  outline-width 1px !important
  outline-offset -1px
  transition all anim-normal ease-in-out
  box-shadow button-shadow

  &:not(:last-of-type)
    margin-right button-margin


  // &,
  // &:active
  // &.active
  //   &:focus
  //   &.focus
  //     tab-focus()

  &:hover
  &:focus
  &.focus
    text-decoration none

  &:active
  &.active
    background-image none
    box-shadow button-shadow-inset

  &.disabled
  &:disabled
    cursor not-allowed
    opacity .65
    box-shadow none

// Future-proof disabling of clicks on `<a>` elements
a.button.disabled
fieldset[disabled] a.button
  pointer-events none


/* Alternate buttons
   ========================================================================== */
.button.is-primary
  button-variant(white, primary)

.button.is-secondary
  button-variant(black, secondary)


/* Flat button
   ========================================================================== */

.button.is-flat
  color primary
  box-shadow none
  outline-color transparent

  &:hover
  &:focus
  &.focus
    outline-color primary
    box-shadow button-shadow

  &:active
  &.active
    outline-color primary !important
    box-shadow button-shadow-inset, button-shadow


/* Iconic buttons
   ========================================================================== */

.button.is-icon
  button-size-icon(button-padding-y)

  // .icon
  //   width 1em

// Link buttons
//
// Make a button look and behave like a link
// .button-link
//   font-weight normal
//   color link-color
//   border-radius 0

//   &,
//   &:active,
//   &.active,
//   &:disabled
//     background-color transparent
//     box-shadow(none)

//   &,
//   &:focus,
//   &:active
//     border-color transparent

//   hover()
//   hover-focus()
//   &:disabled
//     hover-focus()
// //
// // Button Sizes
// //
// .button-lg
//   // line-height ensure even-numbered height of button next to large input
//   button-size($button-padding-y-lg, $button-padding-x-lg, $font-size-lg, $button-border-radius-lg)
// .button-sm
//   // line-height ensure proper height of button next to small input
//   button-size($button-padding-y-sm, $button-padding-x-sm, $font-size-sm, $button-border-radius-sm)


/* Block button
   ========================================================================== */

.button.is-block
  display block
  width 100%

// Vertically space out multiple block buttons
.button.is-block + .button.is-block
  margin-top button-margin

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"]
  &.button.is-block
    width 100%
