.section
  padding spacer-sm 0
  +breakpoint-up(sm)
    padding spacer-md 0
  +breakpoint-up(lg)
    padding spacer-lg 0

.section-header {}

.section-title {}

.section-content {}




// ========================================================================== //
// Custom sections
// ========================================================================== //

.section-about
  .columns
    align-items center
