.timeline
  position relative
  clearfix()
  padding spacer-md 0

  +breakpoint-down(sm)
    margin-top (-1 * spacer-md)


// Timeline Line and Bullets
// ========================================================================== //

.timeline::before
    content ''
    position absolute
    top 0
    right spacer + 1.5 * timeline-line-size
    display block
    width timeline-line-size
    height 100%
    border-radius button-border-radius
    background timeline-line-color

    // +breakpoint-up(sm)
    //   left 50%
    //   margin-left (timeline-line-size / -2)


.timeline-item::after
  content ''
  position absolute
  top 0
  right spacer
  display block
  width 4 * timeline-line-size
  height @width
  margin-top (@height / -2)
  background body-bg
  border timeline-line-size solid timeline-line-color
  border-radius 50%

  // +breakpoint-up(sm)
  //   top spacer
  //   right (-2 * timeline-line-size)
  //   margin-top (-1 * timeline-line-size)
  //   margin-left (timeline-line-size / -2)




// Timeline Item
// ========================================================================== //

.timeline-item
  position relative
  margin 0 0 spacer-lg
  padding spacer

  // Background
  &::before
    content ''
    overlay()
    display block
    background body-bg
    border 1px solid gray-light
    box-shadow timeline-item-shadow
    pointer-events none

  > *
    position relative

  // Inner spacing fix
  > :last-child
    margin-bottom 0

  // Outer spacing fix
  &:last-child
    margin-bottom 0


// Hover state
.timeline-item:hover
  &::before
    box-shadow timeline-item-hover-shadow




// Timeline Item componetns
// ========================================================================== //

.timeline-time
  display block
  margin-bottom (spacer / 2)
  font-weight bold
  color gray-dark
  text-align right
  uppercase()


.timeline-title
  margin-bottom (spacer / 2)
  text-transform none
  font-weight normal
  font-size size-4




// Grid
// ========================================================================== //

// .timeline-item
//   +breakpoint-up(sm)
//     float left
//     width 50%
//     padding-right spacer-md + spacer

//     &::before
//       margin-right spacer-md


//     &:nth-child(even)
//       float right
//       padding-left spacer + spacer-md
//       padding-right spacer

//       &::before
//         margin-left spacer-md
//         margin-right 0

//       .timeline-time
//         text-align left


//     &:nth-child(2)
//       margin-top spacer-lg

// Bullet
.timeline-item:nth-child(even)
  // +breakpoint-up(sm)
  //   &::after
  //     right auto
  //     left (-2 * timeline-line-size)
  //     margin-left 0
  //     margin-right (timeline-line-size / -2)
