.container
  container()
  container-width()


.container-inner
  container()


.container-canvas
  position relative


.container-text
  container()
  max-width 30rem

  .container &
    padding 0


.columns
  row()


.container.columns
  row-container()


.column
  column()


for i in 0..10
  .column.weight-{i}
    flex i i 1px

for i in 0..10
  .column.order-{i}
    order i
