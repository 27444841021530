/* ==========================================================================
   Covers
   ========================================================================== */

.cover
  overlay()

  &::before,
  &::after
    overlay()
    content ''
    background transparent no-repeat center
    background-size cover

  &::after
    background #000
    opacity .8


/* Colors and modifiers
   ========================================================================== */

.cover.primary
  &::after
    background primary

.cover.contain
  &::before
    background-size contain

.cover.bottom
  &::before
    background-position center bottom


/* Images
   ========================================================================== */

.cover.advisor::before
  background-image url(../images/covers/advisor.jpg)

.cover.bag::before
  background-image url(../images/covers/bag.jpg)

.cover.business-people::before
  background-image url(../images/covers/business-people.svg)

.cover.entrepreneurs::before
  background-image url(../images/covers/entrepreneurs.svg)

.cover.city::before
  background-image url(../images/covers/city.jpg)

.cover.map::before
  background-image url(../images/covers/map.jpg)

.cover.man-coffee::before
  background-image url(../images/covers/man-coffee.jpg)

.cover.mountain::before
  background-image url(../images/covers/mountain.jpg)

.cover.partnership::before
  background-image url(../images/covers/partnership.jpg)
