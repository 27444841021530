/* ==========================================================================
   Navs
   ========================================================================== */

.nav
  position relative
  margin 0
  padding 0
  list-style-type none


/* Items
   ========================================================================== */

.nav-item
  display block

  +breakpoint-up(sm)
    display inline-block


/* Links
   ========================================================================== */

.nav-link
  position relative
  display block
  padding nav-padding-y nav-padding-x
  color inherit
  text-decoration inherit
  transition anim-normal

  &:hover
  &:focus
    color inherit
    text-decoration inherit

  +breakpoint-up(sm)
    &::after
      content ''
      position relative
      top .5rem
      display block
      width 100%
      height 0
      border-bottom 2px solid
      margin-bottom -2px
      opacity 0
      transition anim-normal

    &:hover
    &:focus
      &::after
        top 0
        opacity .8


/* Headers
   ========================================================================== */

/* .nav-item contains a .nav-header if it is a parent of .nav */

.nav-header
  position relative
  // display table
  margin 0

.nav-header .nav-link {}
  // display table-cell
  // vertical-align bottom

//   h1, h2, h3, h4, h5, h6,
//   .h1, .h2, .h3, .h4, .h5, .h6
//     display table
//     width 100%
//     height 100%
//     margin 0
//   a
//     display table-cell
//     width 100%
//     height 100%
//     padding nav-padding-y nav-padding-x
//     box-sizing border-box
//     vertical-align bottom

// .nav-menu
//   padding 0
//   list-style-type none

//   li
//     display block
//     &::before
//       content none

//   a
//     padding nav-padding-y nav-padding-x

