// ========================================================================== //
//
// ========================================================================== //
// ========================================================================== //

// Global image class
// .image is <figure>, containing <img>

.image
  img
    display block
    margin 0 auto
    max-width 100%
    max-height 100%



// ========================================================================== //
// Circle gallery
// ========================================================================== //

.circle-gallery
  position relative
  width 100%
  height 0
  padding-bottom 100%
  // overflow hidden
  border-radius 50%
  background gray-lighter
  box-shadow circle-gallery-shadow

  &::before
  &::after
    content ''
    overlay()
    display block
    border-radius 50%
  &::before
    margin 2 * circle-gallery-border
    background url(../images/logos/ioit-color.svg) no-repeat center
    background-clip content-box
    background-size contain
  &::after
    border circle-gallery-border solid white-light
    box-shadow circle-gallery-shadow-inset

  .no-js &
  &:empty
    background white-light

    &::after
      content none



.circle-gallery .image
  overlay(circle-gallery-border)
  overflow hidden

  img
    width 100%
    height 100%
    object-fit cover
