// Messages
// for typed.js strings

.message {}

.message-container
  margin 0 (-1 * spacer-sm)
  padding (spacer-sm / 2) spacer-sm
  border solid primary
  border-width 1px 0
  min-height (3em * line-base)
  box-sizing content-box

  +breakpoint-up(sm)
    margin 0 (spacer-md / -2)
    padding (spacer-md / 4) (spacer-md / 2)
    font-size 2em

  +breakpoint-up(lg)
    margin 0 (spacer-lg / -2)
    padding (spacer-lg / 4) (spacer-lg / 2)

.message-container .typed
  font-weight bold

.message-actions
  clearfix()
  margin-top spacer-sm

.message-actions .button
  float left

.message-actions .register-button
  float right
