.partner-article
  section()

.partner-details
  align-self center

// .partner-header
//   row()
//   align-items flex-end

.partner-heading
  // column()
  // flex 1
  // font-size size-2

.partner-heading a
  color inherit
  text-decoration inherit

  &:hover, &:focus
    color link-hover-color


.partner-logo
  // @extend .partner-heading
  // flex 1

.partner-logo img
  display block
  padding spacer
  margin 0 (-1 * spacer)
  width 100%
  box-shadow 0 2px 4px rgba(0,0,0,.5)


.partners-list
  padding 0
  row()
  justify-content space-between
  list-style none

  li::before
    content none

.partners-item
  column()
  height 6rem

  img
    display block
    // margin 0 auto
    max-width 100%
    max-height 100%

  +breakpoint-up(lg)
    height 8rem
