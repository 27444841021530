/* ==========================================================================
   Navpane
   --------------------------------------------------------------------------
   Navigation pane, sidebar on mobile, as row on desktop and tablet
   ========================================================================== */

.navpane
  position relative

  +breakpoint-down(sm)
    position fixed
    top 0
    right 0
    bottom 0
    left 0
    z-index zindex-navpane
    margin navbar-height navbar-height 0 0
    max-width 20rem
    overflow-x hidden
    overflow-y scroll
    background navbar-bg

    .offset &
      box-shadow navbar-shadow

  +breakpoint-up(sm)
    position relative

  +breakpoint-up(lg)
    overlay()


/* Navs
   ========================================================================== */

.navpane-nav {}


.navpane > .navpane-nav
  +breakpoint-up(sm)
    position relative
    display flex
    flex-wrap wrap
    justify-content space-between

  +breakpoint-up(lg)
    overlay()
    flex-direction column


/* Items
   ========================================================================== */

// .navpane .navpane-item
//   +breakpoint-up(sm)
//
//   &::before
//   &::after
//     content ''
//     overlay()
//     background primary no-repeat center
//     background-size cover
//     transition anim-slowest ease-out
//
//   &::after
//     background: linear-gradient(to top,
//       rgba(primary,.8) 0,
//       rgba(primary,.5) 50%,
//       transparent 100%);
//
//   &:hover::before
//     transform scale(1.2)
//
// +breakpoint-up(lg)
//   width header-sidebar-width


/* Root items */

.navpane-item-root
  position relative

  +breakpoint-up(sm)
    z-index 10
    flex 1 1 1px
    order 1
    display none
    .index &
      display flex
    flex-direction column
    // min-height header-height-min
    // height header-height
    padding-bottom 0
    height 100%
    color white

    &::before
    &::after
      content ''
      overlay()
      display block
      background primary no-repeat center
      background-size cover

    &::after
      background linear-gradient(to top,
        rgba(primary, .5) 0,
        rgba(primary, .5) 50%,
        transparent 100%)

    > .navpane-header
    > .navpane-nav
      position relative
      z-index 10

    > .navpane-header
      flex 1
      display table


    // transform rotate(-90deg)
    // transform-origin top
    // &:nth-child(even)
    //   transform rotate(-90deg)
    //   transform-origin right

    // .index &
    //   min-height header-index-height-min
    //   height header-index-height
    //   box-shadow header-shadow

    +breakpoint-up(lg)
      // margin 0 auto
      // width 100%
      // container-width()
      // min-height auto
      // height auto

      // .index &
      //   width (100% - header-sidebar-width)
      width header-sidebar-width

.navpane-item-root.active
  +breakpoint-up(sm)
    z-index 20
    display block
    flex 0 0 100%
    order 0
    padding 0 space(md)
    width 100%
    box-shadow header-shadow
    transform none

    &::before
    &::after
      content none

    > .navpane-header
      transform none

    > .navpane-nav
      display block
      margin 0 (-1 * nav-padding-x)

  +breakpoint-up(lg)
    padding 0 space(lg)
    color body-color
    .index &
      width (100% - header-sidebar-width)


/* Links
   ========================================================================== */

.navpane-link
  +breakpoint-down(sm)
    outline-color gray
    outline-offset -1px

    &:hover
    &:focus
      background gray-light

    &:active
      background gray-light
      outline 1px solid gray
      outline-offset (-.4 * nav-padding-y)

  +breakpoint-up(sm)
    uppercase()


/* Links of active nav */
.active .navpane-link
  &::after
    position absolute
    top 0
    right 0
    left 0
    border-bottom 0 solid primary

  &:hover
  &:focus
    &::after
      border-bottom-width 4px


/* Link af active item */
.active > .navpane-link
  +breakpoint-down(sm)
    background primary
    color white
    outline 1px solid
    outline-offset (-.4 * nav-padding-y)

  +breakpoint-up(sm)
    color primary

    &::after
      border-bottom-width 4px
      opacity 1


/* Headers
   ========================================================================== */

.navpane-header
  width 100%

  // +breakpoint-down(lg)
  display table
    // height 0
    // padding-bottom (9/16) * 100%
    // border-top 1px solid gray-lightest
    // border-bottom 1px solid gray-light

    &::before
      content ''
      position relative
      display table-cell
      width 0
      height 0
      padding 0 0 ((9/16) * 100%) 0

  +breakpoint-down(sm)
    background primary no-repeat center
    background-size cover
    color white

    &::after
      overlay()
      content ''
      display block
      background: linear-gradient(to top,
        rgba(primary,.8) 0,
        rgba(primary,.5) 65%,
        transparent 100%);

  // +breakpoint-up(sm)
  //   flex 1
  //   z-index 5


/* Header link */
.navpane-header .navpane-link
  // +breakpoint-down(lg)
  position relative
  z-index 1
  display table-cell
  vertical-align bottom

  &:hover
  &:focus
    background none

  +breakpoint-down(sm)
    outline-color inherit


/* Header link of active item */
.active > .navpane-header .navpane-link
  outline 1px solid
  outline-offset (-.4 * nav-padding-y)


/* Hide header of active item */
.active > .navpane-header
  +breakpoint-up(sm)
    display none


// // Header links
// // ======================================================================== //

// .nav-header a
// .active .nav-header a
//   color gray-light

//   &:hover, &:focus
//     color white

//   +breakpoint-up(sm)
//     padding-left spacer-md
//     padding-right @padding-left

//   // experimental spacing
//   // +breakpoint-up(lg)
//   //   padding-left spacer-lg
//   //   padding-right @padding-left



// // ======================================================================== //
// // Menus
// // ======================================================================== //

// .nav-menu
//   +breakpoint-up(sm)
//     z-index 5
//     display flex
//     justify-content flex-start
//     flex-wrap wrap
//     margin 0 (-1 * nav-padding-x)
//     padding 0 spacer-md nav-padding-y

// // Menu of Active Nav
// // ======================================================================== //

// .nav.active .nav-menu
//   +breakpoint-up(lg)
//     padding-left spacer-lg
//     padding-right @padding-left


// // Menu hover links
// // ======================================================================== //

// .nav-menu a
//   position relative

//   +breakpoint-down(sm)
//     &:hover, &:focus
//       background gray-light

//   +breakpoint-up(sm)
//     uppercase()
//     color white

//     &::before
//       content ''
//       position absolute
//       right 1.5rem
//       bottom (nav-padding-y - navlinks-underline-out)
//       left @right
//       display block
//       border-bottom navlinks-underline-size solid
//       opacity 0
//       transition inherit

//     &:hover, &:focus
//       color white-light

//       &::before
//         bottom (nav-padding-y - navlinks-underline-space)
//         opacity navlinks-underline-opacity


// // Menu active links
// // ======================================================================== //

// .nav-menu a:active
// .nav-menu .active a
//   background primary
//   color white-light

//   +breakpoint-up(sm)
//     background transparent
//     color primary


// // Menu active links of Active Nav
// // ======================================================================== //

// .nav.active a
//   +breakpoint-up(sm)
//     color black

//     &::before
//       top 0
//       right 0
//       left 0
//       bottom auto
//       border-bottom-width 0

//     &:hover, &:focus
//       color inherit

//       &::before
//         color primary
//         border-bottom-width (2 * navlinks-underline-size)

// .nav.active .active > a
// .nav.active a:active
//   +breakpoint-up(sm)
//     color primary

//     &::before
//       color primary
//       border-bottom-width (2 * navlinks-underline-size)
//       opacity .8


  // ======================================================================== //
  // Navs customization
  // ======================================================================== //

  .nav-ioit
    +breakpoint-down(sm)
      .nav-header
        padding-bottom (2/3) * 100%
        background-image url(../images/covers/workspace-side.jpg)
    +breakpoint-up(sm)
      &::before
        background-image url(../images/covers/workspace-side.jpg)


      &:not(.active)
        .nav-header::after
          content '\e800'
          position absolute
          top 50%
          left 50%
          display block
          width 1.5em
          height 1.5em
          font size-1/1.35 "fontello"
          color white
          text-align center
          border .1em solid white
          border-radius 50%
          speak none
          opacity .5
          transform translate(-50%, -50%)
          transition anim-normal
          cursor pointer
          pointer-events none
        &:hover
          .nav-header::after
            opacity .8
            transform translate(-80%,-50%)

        .nav-menu
          display none


/* Nesting and sequences
   ========================================================================== */

/* Nested navigation */
.navpane-nav .navpane-nav
  +breakpoint-down(sm)
    padding-top (.25 * spacer)
    padding-bottom @padding-top


/* Bottom border for each section */
.navpane > .navpane-nav > .navpane-item:not(:last-of-type)
  +breakpoint-down(sm)
    margin-bottom (.25 * spacer)
    border-bottom 1px solid gray-light

.navpane-nav:not(:last-of-type)
  +breakpoint-down(sm)
    margin-bottom (.25 * spacer)
    border-bottom 1px solid gray-light


/* Styles for custom navs
   ========================================================================== */

.navpane-item-ioit
  +breakpoint-down(sm)
    > .navpane-header
      background-image url(../images/covers/workspace-side.jpg)

      &::before
        padding-bottom (2/3) * 100%

  +breakpoint-up(sm)
    &::before
      background-image url(../images/covers/workspace-side.jpg)

    &:not(.active)
      .nav-header::after
        content '\e800'
        position absolute
        top 50%
        left 50%
        display block
        width 1.5em
        height 1.5em
        font size-1/1.35 "fontello"
        color white
        text-align center
        border .1em solid white
        border-radius 50%
        speak none
        opacity .5
        transform translate(-50%, -50%)
        transition anim-normal
        cursor pointer
        pointer-events none
      &:hover
        .nav-header::after
          opacity .8
          transform translate(-80%,-50%)

      .navpane-nav
        display none


.navpane-item-isdb
  +breakpoint-down(sm)
    > .navpane-header
      background-image url(../images/covers/analytics.jpg)

  +breakpoint-up(sm)
    &::before
      background-image url(../images/covers/analytics.jpg)


.navpane-item-web
  +breakpoint-down(sm)
    > .navpane-header
      background-image url(../images/covers/brackets.jpg)

  +breakpoint-up(sm)
    &::before
      background-image url(../images/covers/brackets.jpg)




/* Social icons
   ========================================================================== */

navsocial-size-extra = .25

.navsocial-nav
  position relative
  text-align center
  z-index zindex-navpane

  +breakpoint-up(sm)
    position absolute
    top 0
    right navbar-height
    margin-right (-1 * nav-padding-x + (nav-padding-y - navsocial-size-extra / 2) + .2)

  .index &
    display none


.navsocial-item
  display inline-block

  &:not(:last-child)
    margin-right (nav-padding-x - nav-padding-y)

  +breakpoint-up(sm)
    margin-right 0


.navsocial-link
  @extend .navpane-link

  box-sizing content-box
  padding nav-padding-y - (navsocial-size-extra / 2)
  // width 1rem + extra-size
  // height 1rem + extra-size
  // border 1px solid primary
  // border-radius 50%
  font-size 1rem + navsocial-size-extra
  color primary

  &::after
    position absolute
    top 0
    right 0
    left 0
    border-bottom 0 solid primary

  &:hover
  &:focus
    color primary-dark

    &::after
      border-bottom-width 4px
