.offset
  +breakpoint-down(sm)
    overflow hidden


.offset-canvas
  +breakpoint-down(sm)
    transition anim-normal

    // .offset &
      // transform "translate(calc(100% - %s),0)" % navbar-height

      // Overlay
      // &::before
      //   content ''
      //   fill-parent()
      //   z-index zindex-navbar - 10
      //   display block
      //   background #000
      //   opacity .5


.offset-sidebar
  +breakpoint-down(sm)
    position fixed
    left 0
    transform translate(-100%, 0)
    transition anim-normal

    .offset &
      transform none
