// Screenreaders

.sr-only
  sr-only()


// Headings

.heading
  font-weight bold
  uppercase()
  text-shadow text-shadow

  h1&
  .h1&
    text-shadow text-shadow-lg


.double-lined
  a::first-line
  &::first-line
    font-size .75em

  h1&
  .h1&
    font-size size-2


// Text

.text-thin
  font-weight normal !important

.text-small
  font-size size-sm

.text-large
.lead
  font-size size-lg

.text-left
  text-align left !important

.text-center
  text-align center !important

.text-right
  text-align right !important

.undo-uppercase
  undo-uppercase()


// Responsive

// for scale in rupture.scale-names
//   .hidden-{scale}
//     +above(lookup(scale))
//       display none

// for scale in rupture.scale-names
//   .visible-{scale}
//     +above(lookup(scale + 1))
//       display none
