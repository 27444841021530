// Header Application Bar
.navbar
  position fixed
  top 0
  right 0
  left 0
  z-index zindex-navbar
  display flex
  justify-content space-between
  height navbar-height
  padding 0
  transition background anim-fast, box-shadow anim-fast

  +breakpoint-up(sm)
    position static
    margin 0 auto
    padding 0 spacer-sm
    max-width container-max-width
    height 0
    // outline 1px solid red

    // +breakpoint-up(lg)
    //   padding 0 spacer-lg

    > *
      position relative
      z-index zindex-navbar

    .index &
      margin-right header-sidebar-width


// Logo
.navbar .logo
  logo-extra = 1.5rem

  position relative
  margin (-(logo-extra - 1)) auto 0
  width (navbar-height + logo-extra)
  height (navbar-height + logo-extra)
  padding navbar-logo-padding
  border-radius 50%

  > img
    position relative
    z-index 1

  +breakpoint-down(sm)
    &::after
      position absolute
      top -10px
      right -10px
      bottom 1rem
      left -10px
      z-index 0
      display block


/* Buttons
   ========================================================================== */
.navbar-button
  margin (navbar-padding / 4)
  padding (navbar-padding - @margin)
  width (navbar-height - 2 * @margin)
  height @width
  background transparent
  border 0
  border-radius 50%

  img
    display block
    max-width 100%
    max-height 100%

  &:hover,
  &:focus,
  &:active,
  &.active
    background gray-light // radial-gradient(circle, white-light 0, transparent 80%)
    box-shadow inset 0 0 @padding navbar-bg
    outline 0

  +breakpoint-up(sm)
    display none

  +breakpoint-up(lg)
    z-index z-index-navbar


/* Toggle */
.navbar-button-menu
  position relative

  &::before,
  &::after
    content ''
    display block
    width 100%
    height .25rem
    margin 0 auto 3 * 0.25rem
    background gray-darker
    transition-property transform, box-shadow
    transition-duration anim-normal, anim-fast
    transition-delay 0, anim-normal
  &::before
    margin-top .125rem
    box-shadow 0 2 * 0.25rem gray-darker
  &.active
    &::before,
    &::after
      box-shadow none
      margin-top 0
      margin-bottom 0
      transform rotate(45deg)
      transition-delay anim-fast, 0
    &::after
      margin-top -.25rem
      transform rotate(-45deg)

  +breakpoint-up(sm)
    display none


/* Visibility for scrolling, white on covers on with offset plugin active */
.navbar.above-all
.offset .navbar
.cover ~ .navbar
  +breakpoint-down(sm)
    background navbar-bg
    box-shadow navbar-shadow

    .logo
      background navbar-bg
      box-shadow navbar-shadow

      &::after
        content ''
        background navbar-bg
