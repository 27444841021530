// Bootstrap breapoints
// if undefined
unless breakpoints is defined
  breakpoints = {
    xs: 0,
    sm: 544px,
    md: 768px,
    lg: 992px,
    xl: 1200px
  }


breakpoint-up(index, list = breakpoints)
  if (list[index] == 0)
    {block}
  else
    @media (min-width: list[index])
      {block}


breakpoint-down(index, list = breakpoints)
  if (list[index] == 0)
    {block}
  else
    @media (max-width: list[index] - 1)
      {block}
