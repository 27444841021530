.map
  position: relative
  height: 0
  padding-bottom: 50% // 2x1 dimentions
  overflow: hidden

  // shadow
  &::before
    content: ''
    fill-parent()
    z-index: 10
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5)
    pointer-events: none
    touch-action: none

  // imported map
  iframe
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    border: 0
