// TODO: DEVELOP FOOTER

footer-nav-header-width = 18rem

.footer
  background footer-bg
  color footer-color


// .footer a
//   color inherit
//   text-decoration link-decoration

//   &:hover
//   &:focus
//     color inherit
//     text-decoration link-hover-decoration


/* Footer Navigaton
   ========================================================================== */

// .footer-nav
//   display none
//   padding (spacer-md / 2) 0
//   margin 0 (-1 * spacer-md)

//   +breakpoint-up(sm)
//     display block

//   .nav
//     padding (spacer-md / 2) spacer-md


//   .nav-header
//     display inline-block
//     margin-right spacer-md // TODO: fill with correct space
//     width footer-nav-header-width
//     vertical-align top

//     a
//       padding 0 //(nav-padding-y / 2) (nav-padding-x / 2)
//       color footer-color-dark

//       &:hover, &:focus
//         color footer-color


//   .nav-title
//     font-size size-5


//   .nav-menu
//     position relative
//     display inline-block
//     margin .4rem (nav-padding-x / -2) (nav-padding-y / -2 - 0.4)

//     li
//       display inline-block

//     a
//       padding (nav-padding-y / 2) (nav-padding-x / 2)
//       text-decoration link-decoration

//       +hover-focus
//         text-decoration link-hover-decoration

.footnav-nav
  clearfix()
  // display flex
  // flex-wrap wrap
  // flex-direction column

.footnav-nav .footnav-nav
  // clearfix()
  display block
  // margin 0 (-1 * nav-padding-x)


.footnav-item
  // float left
  display block

  // &:nth-of-type(3n + 1)
  //   clear both


.footnav-item-root
  display block
  float right
  flex 1 1 1px
  width 40%

.footnav-item-ioit
  // flex 0 0 100%
  float left
  width 60%
  height 100%

.footnav-link
  padding (nav-padding-y / 2) nav-padding-x


/* Copyright
   ========================================================================== */

.footer-copy
  padding spacer 0
  background footer-bg-dark
  color footer-color-dark
  box-shadow footer-shadow

  +breakpoint-down(sm)
    text-align center

  p
    margin 0
