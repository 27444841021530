/* ==========================================================================
   Members
   ========================================================================== */


// Configuration
// ========================================================================== //

breakpoints[member] = member-details-min-width + member-photo-min-width + 3 * space(sm)




/* Member container
   ========================================================================== */

.member
  position relative
  height auto
  section()

.member .container
  row-container()
  display flex
  flex-wrap wrap
  align-items flex-start
  height 100%


/* Styling */

.member.bg-gradient
  background linear-gradient(to top,
    gray-light 0,
    transparent 50%)




/* Member details
   ========================================================================== */

.member-details
  column(margin)
  flex 3
  order 2
  min-width member-details-min-width

.member-details.center
  align-self center

.member-details.start-center
  +breakpoint-up(sm)
    padding-top 10%

// .member-header
//   display flex
//   flex-direction column-reverse


.member-position
  uppercase()
  margin-bottom 0
  font-size size-4
  font-weight bold
  color gray


.member-description p:last-child
  margin-bottom 0




/* Member photos
   ========================================================================== */

.member-photo
  column(margin)
  position relative
  flex 1
  order 1
  align-self flex-end
  min-width member-photo-min-width
  margin-bottom spacer

  img
    display block
    width 100%
    // height 100%
    // object-fit contain
    // object-position bottom center
    // +breakpoint-down(xs)
    //   margin 0 auto

  +breakpoint-up(member)
    margin-bottom 0

  +breakpoint-up(sm)
    width 25%
    // order 3

.member-photo.large
  flex 2


.member-photo.bg-free
  if debug
    outline 1px solid red

  align-self flex-end

  +breakpoint-down(sm)
    &::before
    &::after
      content ''
      position absolute
      display block
      border 2px solid gray-dark

    &::before
      top 0
      right 0
      width 75%
      height 75%
      border-bottom-color transparent
      border-left-color transparent

    &::after
      bottom 0
      left 0
      width 100%
      height 50%
      border-top-color transparent
      border-right-color transparent


.member-photo.full-height
  +breakpoint-up(sm)
    margin-top (-1 * spacer-md)
    margin-bottom @margin-top

  +breakpoint-up(lg)
    margin-top (-1 * spacer-lg)
    margin-bottom @margin-top


.member-photo.circle
  overflow hidden
  border (.75 * spacer) solid white-light
  border-radius 50%
  box-shadow 0 0 8px rgba(0,0,0,.15), 0 4px 16px rgba(0,0,0,.25)

  // Inside shadow
  &::after
    content ''
    overlay()
    border-radius 50%
    box-shadow inset 0 0 4px rgba(0,0,0,.15), inset 0 4px 12px rgba(0,0,0,.2)
    pointer-events none




/* Member lists
   ========================================================================== */

.member-list
  position relative

.member-list.striped
  .member .member-photo
    +breakpoint-up(sm)
      order 1

  .member:nth-of-type(even) .member-photo
    +breakpoint-up(sm)
      order 3
