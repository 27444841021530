// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
button-variant(color, background)
  color color !important
  background-color background
  outline-color transparent
  // border-color background

  &:hover
  &:focus
  &.focus
    color color !important
    background-color background
    // border-color background
    outline-color color

  &:active
  &.active
    color color
    background-color background
    outline-color color

    &:hover
    &:focus
    &.focus
      color color
      background-color background // darken(background, 17%)
      // border-color background // darken(border, 25%)

  &.disabled
  &:disabled
    &:hover
    &:focus
    &.focus
      background-color background
      // border-color border

// button-flat-variant(color)
//   color color !important
//   outline-color transparent

//   &:hover
//   &:focus
//   &.focus
//     color color !important
//     outline-color color

//   &:active
//   &.active
//     color color
//     background-color background
//     // border-color background
//     background-image none
//     outline-color color

//     &:hover
//     &:focus
//     &.focus
//       color color

// Button sizes
button-size(padding-y, padding-x, radius)
  outline-offset = .4 * padding-y
  padding (padding-y - outline-offset) (padding-x - outline-offset)
  border transparent solid outline-offset
  border-radius radius

  &:hover
  &:focus
  &.focus
    outline-offset -1 * outline-offset
  &:active
  &.active
    &
    &:hover
    &:focus
    &.focus
      outline-offset -1 * outline-offset

button-size-icon(padding-y)
  outline-offset = .4 * padding-y
  padding (padding-y - outline-offset) (padding-y - outline-offset)