.header
  position relative
  background header-bg
  box-shadow header-shadow
  // margin-bottom spacer-sm
  width 100%
  overflow hidden

  +breakpoint-down(sm)
    padding-top navbar-height

  // +breakpoint-up(sm)
  //   margin-bottom spacer-md

  // +breakpoint-up(lg)
  //   margin-bottom spacer-lg


/* Header content
   ========================================================================== */


.header-content
  container()
  min-height header-height-min
  width 100%
  height header-height
  text-align center

  // experimental
  // height 'calc(100vh - %s)' % spacer-sm
  // +breakpoint-up(sm)
  //   height 'calc(100vh - %s)' % spacer-md
  // +breakpoint-up(lg)
  //   height 'calc(100vh - %s)' % spacer-lg

  .index &
    display flex
    flex-direction column
    justify-content center
    text-align left
    // min-height header-index-height-min
    // height header-index-height

  +breakpoint-up(sm)
    position absolute
    top 0
    left 0
    margin-top navbar-height
    margin-bottom navbar-height
    z-index (zindex-navpane + 1)
    // justify-content flex-end

  +breakpoint-up(lg)
    position relative
    width auto

    .index &
      margin-right header-sidebar-width
      max-width none


/* Headings */
.header-content .heading
  position relative
  top 50%
  font-size size-2
  color gray-darker

  +breakpoint-up(sm)
    font-size 2 * size-1

/* Heading on index page */
.index .header-content .heading
  top 0
  font-size size-3

  +breakpoint-up(sm)
    font-size size-1

// .header-content .heading
//   +breakpoint-up(sm)
//     font-size 6rem
//     margin-bottom 1em

// #contacts .header
//   background gray-lighter

// .header.cover
//   .header-content
//     display block

//     .heading
//       position absolute
//       top 50%
//       left 50%
//       max-width 100%
//       padding spacer 2 * spacer
//       transform translate(-50%,0)
//       background rgba(white-light, .8)
//       outline 1px solid black
//       outline-offset (spacer / -2)
//       box-shadow header-shadow-down

/* Logo
   ========================================================================== */

.header .logo
  +breakpoint-up(sm)
    position absolute
    bottom 50%
    left 50%
    display block
    width 2 * size-1
    height @width
    margin-left (@width / -2)
    border 1px solid gray-light
    background body-bg
    box-shadow 0 0 2px rgba(0,0,0,.15), 0 2px 4px rgba(0,0,0,.25)
    transition anim-normal

    .index &
      display none

    &:hover
    &:focus
      border-color primary
      // box-shadow 0 0 2px rgba(primary,.15), 0 2px 4px rgba(primary,.25)

    &:active
      box-shadow none //1 0 2px rgba(0,0,0,.15), 0 -2px 4px rgba(0,0,0,.25)



/* Covers
   ========================================================================== */

.header-cover
  container(margin)
  overflow hidden
  margin-left 0
  margin-right 0

  +breakpoint-up(sm)
    margin-top navbar-height
    margin-bottom @margin-top
    &::after
      box-shadow inset header-shadow


.header-cover ~ .header-content
  color white

.header-cover ~ .header-content .heading
  color gray-lighter


/* Addons and helpers
   ========================================================================== */

.header-arrow
  position absolute
  right 0
  bottom 0
  left 0
  padding nav-padding-y nav-padding-x
  text-align center
  color inherit

  &::before
  &::after
    content '\e806'
    position relative
    display inline-block
    width 1em
    margin-right .2em
    font-family 'fontello'
    font-style normal
    font-weight normal
    speak none
    transition anim-normal

  &::after
    content '\e807'
    top .2em
    margin-left -1.2em

  &:hover
  &:focus
    color link-color
    text-decoration none
    animation none

  &:active
    color link-hover-color

    &::before
    &::after
      transform translate(0, 20%)

  .index &
    display none
