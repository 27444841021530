.navlinks
.navlinks ul
    padding 0
    list-style-type none

.navlinks li
  display inline-block
  margin-right navlinks-space

  &:last-of-type
    margin-right spacer

  &::before
    content none


.navlinks a
  position relative
  display block
  margin (-1 * navlinks-padding)
  padding navlinks-padding
  font-weight bold
  uppercase()
  text-decoration none
  transition anom-normal

  &::before
    content ''
    position absolute
    left navlinks-padding
    right @left
    bottom (navlinks-padding - navlinks-underline-out)
    display block
    border-bottom navlinks-underline-size solid
    opacity 0
    transition anim-normal

  &:hover, &:focus
    text-decoration none

    &::before
      bottom (navlinks-padding - navlinks-underline-space)
      opacity navlinks-underline-opacity
