html
  +breakpoint-up(xl)
    font-size 1.25rem


// Headings

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6
  margin-bottom 1rem
  font-weight bold
  line-height line-headings
  uppercase()
  // color black


h1, .h1
  font-size size-1
  // +breakpoint-down(sm)
  //   font-size size-sm * size-1

h2, .h2
  font-size size-2

h3, .h3
  font-size size-3

h4, .h4
  font-size size-4

h5, .h5
  font-size size-5

h6, .h6
  font-size size-6
  text-decoration none

// h1, h2, h3,
// .h1, .h2, .h3
//   color gray-darker


// Other

hr
  border-top-color gray
  margin spacer

img
  max-width 100%

input[type="checkbox"],
input[type="radio"]
  vertical-align baseline

small
  font-size size-sm

span
  font-style inherit
  font-weight inherit


// Lists

ul
  padding 0 0 0 spacer
  // list-style-type none

  // li::before
  //   content '\e802'

  //   font-family "fontello"
  //   font-style normal
  //   font-weight normal
  //   speak none

  //   display inline-block
  //   width spacer
  //   color primary
  //   margin 0 0 0 (-1 * spacer)


// Remove spacing

// h1, h2, h3, h4, h5, h6
// p
// ul, ol
//   &:last-child
//     margin-bottom 0
